import { graphql, Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../layout/layout';
import { Stream } from '@cloudflare/stream-react';
import ReactMarkdown from 'react-markdown';
import { GlobalStrings, GlobalVariables } from '../../../common/fragments/MRKConfig';
import { LocaleLink } from '../../common/links/LocaleLink';
import { buildURL, HrefLangs } from '../../layout/header/HrefLangs';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import { TopNav } from '../../layout';
import { Quotation } from '../../common/Quotation';
import { interpolateGlobalVariables } from '../../../common/util/interpolateGlobalVariables';
import { useLocale } from '../../../common/hooks/useLocale';
import { MainFooter } from '../../layout/footer/footer.interfaces';
import { getMetaTagsJSX } from '../../page/page.template';
import { ContentfulAsset } from '../../../contentful/content-types/blades/common/interfaces/contentful-asset.interface';
import { MetaTags } from '../../../contentful/content-types/elements/meta-tags.interface';
import { LearningCenterArticle } from '../../../contentful/content-types/learning-center-article';
import { json } from 'express';
import { Col, Container, Div, H1, H2, H4, Img, Li, P, Row, Ul } from '../../common/Elements';
import { BladeSubNav } from '../../common/blades/BladeSubNav';
import { ContentfulBladeSubNav } from '../../../contentful/content-types/blades/blade-sub-nav';
import { MarkdownBlock } from '../../common/MarkdownBlock';
import '../learning-center.scss';
import { Divider } from '../../common/Divider';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';
import {
  getBladeSubnavFromLearningCenterSubHeader,
  getMainFooterFromLearningCenterFooter,
} from '../../common/refresh-blades/util';
import { useInterpolatedText } from '../../common/InterpolatedText';
import MediaContainer from '../../common/MediaContainer';
import { Icon } from '../../common/Icon';

export const pageQuery = graphql`
  query learningCenterArticle($contentfulId: String!, $locale: String!) {
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }

    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }

    subNav: bladeSubNav(contentfulId: { eq: "KOkdO7HtFpLTr5w9As2EB" }, locale: { eq: $locale }) {
      ...subnavFragment
    }

    learningCenterArticle(contentfulId: { eq: $contentfulId }, locale: { eq: $locale }) {
      contentTypeId
      contentfulId
      urlSlug
      metaTags {
        metaTitle
        metaDescription
        twitterCustomImage {
          ...MediaAssetFragment
        }
        metaImage {
          ...MediaAssetFragment
        }
        facebookCustomImage {
          ...MediaAssetFragment
        }
      }
      metaTitle
      metaDescription
      learningCenterArticleSubHeader {
        ...learningCenterArticleSubHeaderFragment
      }
      learningCenterArticleFooter {
        ...learningCenterArticleFooterFragment
      }
      header
      blurbSubHeader
      objectivesHeader
      objectivesList
      relatedContentLinkText
      relatedContent {
        ...learningCenterArticleLinkFragment
      }
      desktopMainContent
      availableLocales
      localized
      localeList {
        enUS
        zhCN
        zhTW
        frFR
        deDE
        itIT
        jaJP
        koKR
        ptBR
        esLA
        esES
        enAU
        enCA
        enIN
        enGB
        nlNL
        idID
        thTH
        ruRU
        svSE
        viVN
      }
    }
    site {
      siteMetadata {
        baseURL
      }
    }
  }
`;

interface LearningCenterArticleData {
  data: {
    learningCenterArticle: LearningCenterArticle;
    globalStrings: GlobalStrings;
    globalVariables: GlobalVariables;
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    subNav: ContentfulBladeSubNav;
    footerData: MainFooter;
    site: any;
  };
}

function createMarkup(markup: string | undefined) {
  if (markup) {
    return { __html: markup };
  }
}

const LearningCenterArticleTemplate: FC<LearningCenterArticleData & GatsbyTemplateProps<any>> = ({
  data,
  pageContext,
}) => {
  const { globalStrings, globalVariables } = data;
  const { json_values } = globalStrings;
  const article = data.learningCenterArticle;
  const interpolatedMainDesktopcontent = useInterpolatedText(article.desktopMainContent || '');
  const [clicked, setClicked] = useState(false);
  const baseURL = data.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';
  return (
    <Layout
      pageContext={pageContext}
      topNavData={data.navData}
      footerData={
        article.learningCenterArticleFooter
          ? getMainFooterFromLearningCenterFooter(
              article.learningCenterArticleFooter,
              data.footerData
            )
          : data.footerData
      }
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      hrefLangs={HrefLangs(
        pageContext.locale,
        pageContext.pathname,
        pageContext.baseURL,
        (pageContext.allowedHrefLangs as any) || undefined
      )}
    >
      <Helmet>
        <title>{article.metaTags ? article.metaTags.metaTitle : article.header}</title>
        {article.metaTags ? getMetaTagsJSX(baseURL, article.metaTags) : ''}
      </Helmet>

      <Div paddingBottom={[7, 10]} backgroundColor={'blue4'}>
        <Container>
          <Row>
            <Col lg={7}>
              <H1 marginTop={[4, 9]}>{article.header}</H1>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <P fontWeight={4} marginTop={[2, 3]} color="black">
                {article.blurbSubHeader}
              </P>
            </Col>
          </Row>
        </Container>
      </Div>

      {article.learningCenterArticleSubHeader && (
        <Div>
          <BladeSubNav
            blade={getBladeSubnavFromLearningCenterSubHeader(
              article.learningCenterArticleSubHeader,
              globalStrings.json_values.Learning_Center
            )}
            pagePrefix={'/'}
          />
        </Div>
      )}

      <Container>
        <Row marginTop={[5, 9]}>
          <MediaContainer>
            {size => (
              <>
                {size.lg && (
                  <Col lg={3}>
                    <Div marginBottom={6}>
                      <H4>{globalStrings.json_values.Learning_Objectives}</H4>
                      <P fontWeight={6} color={'black'} fontSize={2} lineHeight={6}>
                        {globalStrings.json_values.after_reading_with_colon}
                      </P>
                      {article.objectivesList && (
                        <Div className="markdown-block">
                          <Ul className="markdown-feature-list">
                            {article.objectivesList.map(objective => (
                              <Li
                                key={objective}
                                className="markdown-feature-listitem body-1 f2"
                                marginBottom={2}
                              >
                                {objective}
                              </Li>
                            ))}
                          </Ul>
                        </Div>
                      )}
                    </Div>
                    {article.relatedContent && article.relatedContentLinkText && (
                      <Div className="sticky-sidebar" marginBottom={11}>
                        <P
                          fontSize={2}
                          fontWeight={7}
                          marginTop={6}
                          marginBottom={0}
                          color="orange0"
                          className="ttu"
                        >
                          {globalStrings.json_values.Related_Content}
                        </P>
                        <Divider paddingVertical={2} />
                        <Div className="below-divider">
                          {article.relatedContent.map(link => (
                            <LocaleLink key={link.contentfulId} to={link.url}>
                              <P
                                color="black"
                                fontSize={2}
                                fontWeight={6}
                                hovered={{ color: 'bluehover' }}
                                marginVertical={1}
                              >
                                {link.displayText}
                              </P>
                            </LocaleLink>
                          ))}
                        </Div>
                      </Div>
                    )}
                  </Col>
                )}
              </>
            )}
          </MediaContainer>

          <Col lg={{ span: 7, offset: 1 }} marginBottom={[7, 10]}>
            <P
              fontSize={1}
              fontWeight={4}
              marginVertical={0}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setClicked(true);
              }}
              className="pointer"
              color={'blue1'}
            >
              {globalStrings.json_values[clicked ? 'Link_copied' : 'Copy_article_link']}{' '}
              <Icon fill="blue1" type="chain-link" paddingVertical={2} />
            </P>

            {interpolatedMainDesktopcontent && (
              <div
                className="learning-center-content"
                dangerouslySetInnerHTML={createMarkup(interpolatedMainDesktopcontent)}
              ></div>
            )}
          </Col>
          <MediaContainer>
            {size => (
              <>
                {!size.lg && (
                  <Col lg={3}>
                    {article.relatedContent && article.relatedContentLinkText && (
                      <Div className="sticky-sidebar" marginBottom={7}>
                        <P
                          fontSize={2}
                          fontWeight={7}
                          color="orange0"
                          className="ttu"
                          marginVertical={0}
                        >
                          {globalStrings.json_values.Related_Content}
                        </P>
                        <Divider />
                        <Div className="below-divider">
                          {article.relatedContent.map(link => (
                            <LocaleLink key={link.contentfulId} to={link.url}>
                              <P
                                color="black"
                                fontSize={2}
                                fontWeight={6}
                                hovered={{ color: 'bluehover' }}
                                marginVertical={1}
                              >
                                {link.displayText}
                              </P>
                            </LocaleLink>
                          ))}
                        </Div>
                      </Div>
                    )}
                  </Col>
                )}
              </>
            )}
          </MediaContainer>
        </Row>
      </Container>
    </Layout>
  );
};

export default LearningCenterArticleTemplate;
